import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	Elements,
	useElements,
	useStripe,
} from "@stripe/react-stripe-js";
import { StripeCardCvcElementOptions } from "@stripe/stripe-js";
import { ArrowSquareOut } from "@withjuly/julycons/bold";
import { Button, Input, Label, useToast } from "@withjuly/solisv2";
import { useState } from "react";
import { getStripe } from "~/utils/stripe";
import { trpc } from "../Utility/trpc";
import posthog from "posthog-js";
import { AgencyBilling, AgencyBillingStatusSchema } from "@withjuly/fabric";
import { useAgencyStore } from "~/utils/context/agency";

interface PaywallProps {
	billing: AgencyBilling;
}

export const Paywall: React.FC<PaywallProps> = ({ billing }) => {
	return (
		<div className="bg-surface-primary/90 absolute z-[999] flex h-full w-full items-center justify-center backdrop-blur-[10px]">
			<div className="bg-surface-secondary rounded-solis-lg border-stroke-tertiary max-w-[560px] border">
				<Elements stripe={getStripe()}>
					<PaywallBody billing={billing} />
				</Elements>
			</div>
		</div>
	);
};

const PaywallBody: React.FC<PaywallProps> = ({ billing }) => {
	const [name, setName] = useState(() => "");
	const [isCardAddLoading, setIsCardAddLoading] = useState(() => false);

	const { toast } = useToast();

	const createSession = trpc.agency.billing.createSession.useMutation();

	const $setIsPayingCustomer = useAgencyStore(
		(store) => store.setIsPayingCustomer,
	);

	const elements = useElements();
	const stripe = useStripe();

	const inputStyle: StripeCardCvcElementOptions = {
		style: {
			base: {
				fontFamily: "system-ui",
				fontSize: "16px",
				fontWeight: "350",
				color: "#FFFFFF",
				"::placeholder": {
					color: "#DDEFFD45",
				},
			},
		},
	};

	const onSubmit = async () => {
		setIsCardAddLoading(() => true);
		const { clientSecret } = await createSession.mutateAsync();

		if (!stripe || !elements || !clientSecret) return;
		const card = elements?.getElement(CardNumberElement);
		if (!card) return;

		const { error } = await stripe.confirmCardSetup(clientSecret, {
			payment_method: {
				card,
				billing_details: {
					name: name,
				},
			},
		});

		if (error) {
			toast({
				title: "Failed to update payment method",
				description:
					"There was an issue updating your payment method. Please check your details and try again",
				variant: "error",
			});
			setIsCardAddLoading(() => false);
		} else {
			$setIsPayingCustomer(true);

			posthog.capture("Add Payment Method");
		}
	};

	return (
		<div className="flex flex-col">
			<div className="flex w-full flex-col items-start gap-4 p-6">
				<p className="text-header-xl font-repro">
					Please update your payment method
				</p>
				<p className="text-paragraph-md text-text-secondary font-repro">
					{billing.status !== AgencyBillingStatusSchema.enum.canceled &&
					billing.cardLastFour
						? `We tried to charge your payment method ending in ${billing.cardLastFour}, but the charge was unsuccessful. `
						: null}
					To continue using July, please update your payment method. If you
					believe this was a mistake, you can{" "}
					<button className="launch_intercom underline">
						contact July support
					</button>
					.
				</p>
			</div>

			{billing.nextChargeAmount ? (
				<Label
					tooltip="This amount it based on the number of managers and talent you have on July."
					variant="bold"
				>
					Currently due:{" "}
					{(billing.nextChargeAmount / 100).toLocaleString("en-US", {
						style: "currency",
						currency: "USD",
					})}{" "}
					USD
				</Label>
			) : null}

			<div className="bg-stroke-tertiary h-px w-full">{/* DIVIDER */}</div>

			<div className="flex flex-col gap-6 p-6">
				<Input
					name="name"
					placeholder="Add a name"
					className="font-inter"
					autofocus={true}
					label="Name on card"
					onChange={(e) => setName(() => e.target.value)}
				/>
				<div className="flex w-full flex-col gap-2">
					<div className="flex flex-col gap-2">
						<Label variant="overline" size="xs" color="secondary">
							Card information
						</Label>
						<div className="rounded-solis-md bg-slate-alpha-2 focus-within:border-brand flex h-10 w-full items-center justify-center px-3">
							<CardNumberElement options={inputStyle} className="w-full" />
						</div>
					</div>
					<div className="items-centner flex gap-2">
						<div className="rounded-solis-md bg-slate-alpha-2 focus-within:border-brand flex h-10 w-full items-center justify-center px-3">
							<CardExpiryElement options={inputStyle} className="w-full" />
						</div>
						<div className="rounded-solis-md bg-slate-alpha-2 focus-within:border-brand flex h-10 w-full items-center justify-center px-3">
							<CardCvcElement options={inputStyle} className="w-full" />
						</div>
					</div>
				</div>
			</div>

			<div className="flex w-full flex-col gap-4 p-6 pt-0">
				<Button
					onClick={onSubmit}
					disabled={isCardAddLoading}
					isLoading={isCardAddLoading}
				>
					Retry payment
				</Button>

				<div className="flex h-10 w-full items-center justify-center gap-2">
					<p className="text-paragraph-sm text-text-secondary">
						Having issues?
					</p>
					<Button
						className="launch_intercom"
						variant="blank"
						trailingIcon={ArrowSquareOut}
					>
						Contact support
					</Button>
				</div>
			</div>
		</div>
	);
};
