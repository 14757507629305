import { useMemo } from "react";
import { useParams } from "next/navigation";
import { useRouter } from "next/router";
import { cx } from "@withjuly/frontend-common";
import { trpc } from "~/components/Utility/trpc";
import { AGENCY_PUBLIC_ID } from "~/utils/api";
import { useAuth } from "~/utils/context/auth";
import { useDealStore } from "~/utils/context/deal";
import { useDeckStore } from "~/utils/context/deck";
import { useMediaKitStore } from "~/utils/context/mediakit";
import { useReportStore } from "~/utils/context/report";
import { useRosterStore } from "~/utils/context/roster";
import { useQueryClient } from "@tanstack/react-query";

export const OrganizationMenu = () => {
	const utils = trpc.useContext();
	const router = useRouter();
	const params = useParams();
	const queryClient = useQueryClient();
	const { user } = useAuth();

	const $resetDealState = useDealStore((state) => state.resetState);
	const $resetDeckState = useDeckStore((state) => state.resetState);
	const $resetMediaKitsState = useMediaKitStore((state) => state.resetState);
	const $resetReportStae = useReportStore((state) => state.resetState);
	const $resetRosterState = useRosterStore((state) => state.resetState);

	const switchAgency = async (agencyPublicId: string) => {
		localStorage.setItem(AGENCY_PUBLIC_ID, agencyPublicId);

		// Navigate to the same route within the new agency
		const currentPath = router.asPath;
		const newPath = currentPath.replace(/\/[^/]+/, `/${agencyPublicId}`);
		router.push(newPath);

		queryClient.resetQueries({
			predicate: (query) => {
				const key: string[] =
					query.queryKey.length > 0 ? (query.queryKey[0] as string[]) : [];
				if (key.length !== 2) return false;
				return !(
					(key[0] === "user" && key[1] === "current") ||
					(key[0] === "agency" && key[1] === "get")
				);
			},
		});
		utils.agency.invalidate();

		$resetDealState();
		$resetDeckState();
		$resetMediaKitsState();
		$resetReportStae();
		$resetRosterState();
	};

	const organizationAgencies = useMemo(
		() =>
			user?.organizations.map((organization) => organization.agencies).flat(),
		[user],
	);

	const combinedAgencies = useMemo(
		() =>
			user !== undefined
				? [
						...user.agencies,
						...user.organizations
							.map((organization) => organization.agencies)
							.flat(),
					]
				: [],
		[user],
	);

	return user && combinedAgencies.length > 1 ? (
		<div className="relative flex h-full max-h-screen min-h-screen w-full flex-col gap-6 overflow-y-auto px-4 py-6">
			{/* Render agencies associated with an organization */}
			{user.organizations.map((organization) => (
				<div
					key={organization.publicId}
					className="outline-stroke-secondary flex flex-col gap-2 rounded-md outline outline-2 outline-offset-4"
				>
					{organization.agencies.map((agency) =>
						agency.agency.logoUrl ? (
							<img
								key={agency.agency.publicId}
								className={cx(
									"bg-surface-tertiary h-8 min-h-8 w-8 min-w-8 cursor-pointer rounded-md outline outline-2 outline-offset-4 outline-transparent transition-all",
									params &&
										params.agency_id &&
										params.agency_id === agency.agency.publicId
										? "outline-sky-6 hover:outline-brand my-2 first:mt-0 last:mb-0"
										: "",
								)}
								src={agency.agency.logoUrl}
								alt="July"
								onClick={() => switchAgency(agency.agency.publicId)}
							/>
						) : (
							<div
								key={agency.agency.publicId}
								className={cx(
									"bg-surface-hover-1 text-paragraph-sm font-repro flex h-8 min-h-8 w-8 min-w-8 cursor-pointer items-center justify-center rounded-md outline outline-2 outline-offset-4 outline-transparent transition-all",
									params &&
										params.agency_id &&
										params.agency_id === agency.agency.publicId
										? "outline-sky-6 hover:outline-brand my-2 first:mt-0 last:mb-0"
										: "",
								)}
								onClick={() => switchAgency(agency.agency.publicId)}
							>
								{agency.agency.name[0]?.toUpperCase()}
							</div>
						),
					)}

					{/* <div
						className={cx(
							"bg-surface-hover-1 flex h-8 min-h-8 w-8 min-w-8 cursor-pointer items-center justify-center rounded-lg",
						)}
					>
						<Gear className="text-text-secondary h-4 w-4" />
					</div> */}
				</div>
			))}

			{/* Render agencies not associated with an organization — filter out agencies that are both assigned as a manager and within an organization */}
			{user.agencies
				.filter(
					(agency) =>
						!(organizationAgencies ?? []).some(
							(orgAgency) => agency.agency.uuid === orgAgency.agency.uuid,
						),
				)
				.map((agency) =>
					agency.agency.logoUrl ? (
						<img
							key={agency.agency.publicId}
							className={cx(
								"bg-surface-tertiary h-8 min-h-8 w-8 min-w-8 cursor-pointer rounded-md outline outline-2 outline-offset-4 transition-all",
								params &&
									params.agency_id &&
									params.agency_id === agency.agency.publicId
									? "outline-sky-6 hover:outline-brand"
									: "outline-stroke-secondary hover:outline-stroke-primary",
							)}
							src={agency.agency.logoUrl}
							alt="July"
							onClick={() => switchAgency(agency.agency.publicId)}
						/>
					) : (
						<div
							key={agency.agency.publicId}
							className={cx(
								"bg-surface-hover-1 text-paragraph-sm font-repro flex h-8 min-h-8 w-8 min-w-8 cursor-pointer items-center justify-center rounded-md outline outline-2 outline-offset-4 outline-transparent transition-all",
								params &&
									params.agency_id &&
									params.agency_id === agency.agency.publicId
									? "outline-sky-6 hover:outline-brand"
									: "outline-stroke-secondary hover:outline-stroke-primary",
							)}
							onClick={() => switchAgency(agency.agency.publicId)}
						>
							{agency.agency.name[0]?.toUpperCase()}
						</div>
					),
				)}
		</div>
	) : null;
};
