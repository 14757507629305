import AppReroute from "~/utils/AppReroute";
import React, { ReactElement, useEffect, useMemo, useState } from "react";
import { AgencyMenu } from "~/components/Navigation/Agency/AgencyMenu";
import { IntercomProvider } from "~/utils/IntercomProvider";
import { useAgency, useAgencyStore } from "~/utils/context/agency";
import { Onboarding } from "~/components/Agency/Onboarding";
import { ManagerOnboarding } from "~/components/Agency/ManagerOnboarding";
import { useAuth } from "~/utils/context/auth";
import { Paywall } from "~/components/Agency/Paywall";
import { AgencyBillingStatusSchema } from "@withjuly/fabric";
import { useAgencyBilling } from "~/utils/context/agency-billing";
import { useFeatureFlagEnabled } from "posthog-js/react";
import * as Dialog from "@radix-ui/react-dialog";
import { ArrowsOutSimple } from "@withjuly/julycons/bold";
import { useWindowSize } from "@withjuly/frontend-common";
import { useParams, useRouter } from "next/navigation";
import { Loader } from "@withjuly/solis";
import { AGENCY_PUBLIC_ID } from "~/utils/api";
import { trpc } from "~/components/Utility/trpc";
import { OrganizationMenu } from "~/components/Navigation/Organization/OrganizationMenu";
import { CommandK } from "~/components/CommandK";

interface AgencyLayoutProps {
	children: ReactElement;
}

export const AgencyLayout: React.FC<AgencyLayoutProps> = (props) => {
	return (
		<AppReroute allowedAccountTypes={["agency"]}>
			<IntercomProvider>
				<AgencyLayoutInternal {...props} />
			</IntercomProvider>
		</AppReroute>
	);
};

export const AgencyLayoutInternal: React.FC<AgencyLayoutProps> = ({
	children,
}) => {
	const params = useParams<{ agency_id: string }>();
	const router = useRouter();

	const utils = trpc.useContext();
	const { agencyProfile } = useAgency();
	const { user } = useAuth();
	const billing = useAgencyBilling();

	const [isClient, setIsClient] = useState(() => false);
	const isPaywallEnabled = useFeatureFlagEnabled("agency-paywall");

	const $isPayingCustomer = useAgencyStore((store) => store.isPayingCustomer);

	useEffect(() => {
		setIsClient(() => true);
	}, []);

	useEffect(() => {
		if (
			params &&
			params.agency_id &&
			user &&
			((user.agencies && user.agencies.length > 0) ||
				(user.organizations &&
					user.organizations.some((org) => org.agencies.length > 0)))
		) {
			const agencies = user.agencies.map((agency) => agency);
			const organizationAgencies = user.organizations
				.map((organization) => organization.agencies)
				.flat();
			const combinedAgencies = [...agencies, ...organizationAgencies];

			if (
				!combinedAgencies.some(
					(agency) => agency.agency.publicId === params.agency_id,
				)
			) {
				const id = combinedAgencies[0]?.agency.publicId ?? "";
				localStorage.setItem(AGENCY_PUBLIC_ID, id);
				router.push(`/${id}`);
				utils.invalidate();
			}
		}
	}, [params, user, router, utils]);

	const showPaywall = useMemo(() => {
		if (!isPaywallEnabled || !agencyProfile || !billing || $isPayingCustomer) {
			return false;
		}

		return (
			isClient &&
			!agencyProfile.isLegacyBilling &&
			billing.status !== AgencyBillingStatusSchema.enum.active &&
			billing.status !== AgencyBillingStatusSchema.enum.trialing
		);
	}, [isPaywallEnabled, agencyProfile, billing, $isPayingCustomer, isClient]);

	const combinedAgencies = useMemo(
		() =>
			user !== undefined
				? [
						...user.agencies,
						...user.organizations
							.map((organization) => organization.agencies)
							.flat(),
					]
				: [],
		[user],
	);

	if (!params || !params.agency_id || !user) {
		return (
			<div className="flex h-full items-center justify-center">
				<Loader />
			</div>
		);
	}

	if (
		agencyProfile &&
		!agencyProfile.hasCompletedOnboarding &&
		!$isPayingCustomer
	) {
		return <Onboarding />;
	}

	if (user && (user.firstName === "" || user.lastName === "")) {
		return <ManagerOnboarding user={user} />;
	}

	return (
		<div className="flex h-full max-h-[100vh] min-h-[100vh] flex-row gap-0">
			<MobileBlocker />

			{/* Render Organization Switcher Menu */}
			{combinedAgencies.length > 0 ? (
				<nav className="bg-surface-secondary border-stroke-tertiary flex-shrink-0 border-r">
					<OrganizationMenu />
				</nav>
			) : null}

			{/* Render Agency */}
			<div className="bg-surface-primary relative flex w-full flex-1 flex-row overflow-y-auto overflow-x-clip">
				{showPaywall && billing ? <Paywall billing={billing} /> : null}

				<div className="z-20">
					<AgencyMenu />
				</div>
				<div className="flex-1 overflow-x-auto">{children}</div>

				<CommandK />
			</div>
		</div>
	);
};

//
const MobileBlocker: React.FC = () => {
	const [isClient, setIsClient] = useState(() => false);
	const { width } = useWindowSize();

	const isOpen = useMemo(() => {
		return width !== undefined && isClient && width <= 800;
	}, [isClient, width]);

	useEffect(() => {
		setIsClient(() => true);
	}, []);

	return (
		<Dialog.Root open={isOpen} modal={true}>
			<Dialog.Portal>
				<Dialog.Overlay className="bg-surface-primary fixed inset-0 z-[1000]" />
				<Dialog.Content className="fixed left-1/2 top-1/2 z-[2000] -translate-x-1/2 -translate-y-1/2 transform flex-col items-center justify-center focus:outline-none focus:ring-0">
					<div className="flex w-[640px]  max-w-[300px]  flex-col items-center justify-center gap-6">
						<ArrowsOutSimple className="text-brand h-8 w-8" />
						<div className="flex flex-col items-center justify-center gap-3">
							<p className="text-header-lg font-repro text-center">
								Increase screen size
							</p>
							<p className="text-paragraph-sm font-repro text-text-secondary text-center">
								Please increase your screen size <br /> or switch to a larger
								device.
							</p>
						</div>
					</div>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};
