import { useEffect, useState } from "react";
import {
	Avatar,
	AvatarFallback,
	AvatarImage,
	Badge,
	BadgeLabel,
	Color,
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	ScrollArea,
} from "@withjuly/solstice";
import {
	Briefcase,
	ChartLineUp,
	Presentation,
	User,
	UsersFour,
	Target,
} from "@withjuly/julycons/bold";
import { Kanban } from "@withjuly/julycons/fill";
import { trpc } from "~/components/Utility/trpc";
import { useAgency } from "~/utils/context/agency";
import { useRouter } from "next/navigation";
import { useDealStore } from "~/utils/context/deal";
import { ArrowUpRight, Check } from "lucide-react";
import { useFeatureFlagEnabled } from "posthog-js/react";

export const CommandK = () => {
	const agency = useAgency();
	const router = useRouter();
	const isCrmEnabled = useFeatureFlagEnabled("agency-deals");
	const isBrandDatabaseEnabled = useFeatureFlagEnabled("agency-brands");

	// Store
	const $deals = useDealStore((store) => store.deals);
	const $setDeals = useDealStore((store) => store.setDeals);

	// Fetch Brands
	const { data: previewBrands } = trpc.agency.brands.previewBrands.useQuery({});
	const { data: previewMarketingAgencies } =
		trpc.agency.brands.previewMarketingAgencies.useQuery({});

	// Fetch Deals
	const { data: dealColumns } = trpc.agency.deals.getColumns.useQuery();
	trpc.agency.deals.getDealsPreview.useQuery(
		{},
		{
			refetchOnWindowFocus: false,
			staleTime: 20 * 60 * 1000,
			onSuccess: (deals) => {
				$setDeals(deals);
			},
		},
	);

	const { data: mediaKits } = trpc.mediaKit.getAll.useQuery();
	const { data: decks } = trpc.agency.decks.getDecks.useQuery({});
	const { data: reports } = trpc.agency.reports.getAll.useQuery();

	const [open, setOpen] = useState(false);
	const [usernameCopied, setUsernameCopied] = useState<string | undefined>(
		undefined,
	);

	const navigate = (path: string, query?: Record<string, string>) => {
		const queryParams = query
			? `?${new URLSearchParams(query).toString()}`
			: "";

		router.push(`/${agency.agencyProfile?.publicId}${path}${queryParams}`);
		setOpen(false);
	};

	useEffect(() => {
		const down = (e: KeyboardEvent) => {
			if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				setOpen((open) => !open);
			}

			// Copy media kit to clipboard if selected element has data-kit-username to copy
			if (e.key === "c" && (e.metaKey || e.ctrlKey)) {
				const selectedElement = document.querySelector(
					'[data-selected="true"]',
				);
				if (selectedElement) {
					e.preventDefault();
					const username = selectedElement.getAttribute("data-kit-username");
					if (username) {
						const baseUrl = window.location.origin;
						const kitUrl = `${baseUrl}/kit/${username}`;
						navigator.clipboard.writeText(kitUrl);
						setUsernameCopied(username);
						setTimeout(() => setUsernameCopied(undefined), 1000);
					}
				}
			}
		};
		document.addEventListener("keydown", down);
		return () => document.removeEventListener("keydown", down);
	}, []);

	return (
		<CommandDialog open={open} onOpenChange={setOpen}>
			<CommandInput placeholder="Type a command or search..." />
			<CommandList className="overflow-y-clip">
				<ScrollArea className="h-[300px] max-h-[300px]">
					<CommandEmpty>No results found</CommandEmpty>

					<CommandGroup heading="Pages">
						{isCrmEnabled ? (
							<CommandItem
								onSelect={() => navigate("/deals")}
								className="group"
							>
								<Kanban className="text-text-tertiary ml-1 mr-0.5" />
								Deals
								{$deals ? (
									<span className="text-text-tertiary">{$deals.length}</span>
								) : null}
								<ArrowUpRight className="text-text-tertiary ml-auto h-4 w-4 opacity-0 group-data-[selected=true]:opacity-50" />
							</CommandItem>
						) : null}

						{isBrandDatabaseEnabled ? (
							<CommandItem
								onSelect={() => navigate("/contacts")}
								className="group"
							>
								<Target className="text-text-tertiary ml-1 mr-0.5" />
								Contacts
								{previewBrands && previewMarketingAgencies ? (
									<span className="text-text-tertiary">
										{previewBrands.length + previewMarketingAgencies.length}
									</span>
								) : null}
								<ArrowUpRight className="text-text-tertiary ml-auto h-4 w-4 opacity-0 group-data-[selected=true]:opacity-50" />
							</CommandItem>
						) : null}

						<CommandItem onSelect={() => navigate("")} className="group">
							<User className="text-text-tertiary ml-1 mr-0.5" />
							Talent
							<ArrowUpRight className="text-text-tertiary ml-auto h-4 w-4 opacity-0 group-data-[selected=true]:opacity-50" />
						</CommandItem>

						<CommandItem onSelect={() => navigate("/roster")} className="group">
							<UsersFour className="text-text-tertiary ml-1 mr-0.5" />
							Roster
							<ArrowUpRight className="text-text-tertiary ml-auto h-4 w-4 opacity-0 group-data-[selected=true]:opacity-50" />
						</CommandItem>

						<CommandItem
							onSelect={() => navigate("/one-sheets")}
							className="group"
						>
							<Presentation className="text-text-tertiary ml-1 mr-0.5" />
							Decks
							<ArrowUpRight className="text-text-tertiary ml-auto h-4 w-4 opacity-0 group-data-[selected=true]:opacity-50" />
						</CommandItem>

						<CommandItem
							onSelect={() => navigate("/mediakits")}
							className="group"
						>
							<Briefcase className="text-text-tertiary ml-1 mr-0.5" />
							Media Kits
							<ArrowUpRight className="text-text-tertiary ml-auto h-4 w-4 opacity-0 group-data-[selected=true]:opacity-50" />
						</CommandItem>

						<CommandItem
							onSelect={() => navigate("/reports")}
							className="group"
						>
							<ChartLineUp className="text-text-tertiary ml-1 mr-0.5" />
							Reports
							<ArrowUpRight className="text-text-tertiary ml-auto h-4 w-4 opacity-0 group-data-[selected=true]:opacity-50" />
						</CommandItem>
					</CommandGroup>

					<CommandGroup heading="Media Kits">
						{(mediaKits ?? []).map((mediaKit, index) => (
							<CommandItem
								key={mediaKit.username}
								data-kit-username={mediaKit.username}
								value={`${index}-${mediaKit.displayName ?? mediaKit.username ?? ""}`}
								className="group flex max-w-[582px] flex-row items-center gap-2"
								onSelect={() =>
									navigate("/mediakits", {
										username: mediaKit.username,
										uuid: mediaKit.creatorProfileUuid,
									})
								}
							>
								<Avatar className="h-6 w-6">
									<AvatarImage src={mediaKit.profilePicture}></AvatarImage>
									<AvatarFallback className="h-6 w-6"></AvatarFallback>
								</Avatar>

								<span className="truncate">
									{mediaKit.displayName ?? mediaKit.username ?? ""}
								</span>

								{usernameCopied === mediaKit.username ? (
									<Check className="text-green-6 ml-auto h-4 w-4 opacity-0 group-data-[selected=true]:opacity-100" />
								) : (
									<div className="text-text-tertiary ml-auto flex flex-row items-center gap-2 text-xs opacity-0 group-data-[selected=true]:opacity-50">
										⌘C to copy URL
									</div>
								)}
							</CommandItem>
						))}
					</CommandGroup>

					<CommandGroup heading="Decks">
						{(decks ?? []).map((deck, index) => (
							<CommandItem
								key={deck.uuid}
								value={`${index}-${deck.title}`}
								className="flex max-w-[582px] flex-row items-center gap-2"
								onSelect={() =>
									navigate("/one-sheets", {
										uuid: deck.uuid,
									})
								}
							>
								<Presentation className="text-text-tertiary ml-1 mr-0.5" />
								<span className="truncate">{deck.title}</span>
							</CommandItem>
						))}
					</CommandGroup>

					<CommandGroup heading="Reports">
						{(reports ?? []).map((report, index) => (
							<CommandItem
								key={report.uuid}
								value={`${index}-${report.title}`}
								className="flex max-w-[582px] flex-row items-center gap-2"
								onSelect={() =>
									navigate("/reports", {
										uuid: report.uuid,
									})
								}
							>
								<ChartLineUp className="text-text-tertiary ml-1 mr-0.5" />
								<span className="truncate">{report.title}</span>
							</CommandItem>
						))}
					</CommandGroup>

					<CommandGroup heading="Brands">
						{(previewBrands ?? []).map((brand, index) => (
							<CommandItem
								key={brand.uuid}
								value={`${index}-${brand.name}`}
								className="flex max-w-[582px] flex-row items-center gap-2"
								onSelect={() =>
									navigate("/contacts", {
										type: "brand",
										uuid: brand.uuid,
									})
								}
							>
								<Avatar className="h-6 w-6 rounded-md">
									<AvatarImage src={brand.logoUrl}></AvatarImage>
									<AvatarFallback className="h-6 w-6 rounded-md"></AvatarFallback>
								</Avatar>

								<span className="truncate">{brand.name}</span>

								{brand.categories[0] ? (
									<BadgeLabel
										color={brand.categories[0].color as Color}
										className="ml-auto"
									>
										{brand.categories[0].name}
									</BadgeLabel>
								) : null}

								{brand.categories.length > 1 ? (
									<Badge variant="constructive">
										+ {brand.categories.length - 1} more
									</Badge>
								) : null}
							</CommandItem>
						))}
					</CommandGroup>

					<CommandGroup heading="Marketing Agencies">
						{(previewMarketingAgencies ?? []).map((agency, index) => (
							<CommandItem
								key={agency.uuid}
								value={`${index}-${agency.name}`}
								className="flex max-w-[582px] flex-row items-center gap-2"
								onSelect={() =>
									navigate("/contacts", {
										type: "agency",
										uuid: agency.uuid,
									})
								}
							>
								<Avatar className="h-6 w-6 rounded-md">
									<AvatarImage src={agency.logoUrl}></AvatarImage>
									<AvatarFallback className="h-6 w-6 rounded-md"></AvatarFallback>
								</Avatar>

								<span className="truncate">{agency.name}</span>
							</CommandItem>
						))}
					</CommandGroup>

					<CommandGroup heading="Deals">
						{dealColumns &&
							$deals &&
							($deals ?? []).map((deal, index) => {
								const column = dealColumns.find(
									(column) => column.uuid === deal.column.uuid,
								);

								return (
									<CommandItem
										key={deal.uuid}
										value={`${index}-${deal.title}`}
										className="group group flex max-w-[582px] flex-row items-center gap-2"
										onSelect={() =>
											navigate("/deals", {
												uuid: deal.uuid,
											})
										}
									>
										<div className="flex flex-row -space-x-2">
											{[...deal.managers].map((manager) => (
												<Avatar
													key={manager.uuid}
													className="outline-surface-secondary group-data-[selected=true]:outline-surface-tertiary h-6 w-6 outline outline-2"
												>
													<AvatarImage
														src={manager.profilePictureUrl}
													></AvatarImage>
													<AvatarFallback className="bg-surface-tertiary text-text-secondary h-6 w-6 transition-all">
														{manager.name[0]?.toUpperCase() ?? ""}
													</AvatarFallback>
												</Avatar>
											))}
										</div>

										<span className="mr-auto truncate">{deal.title}</span>

										{column ? (
											<BadgeLabel
												color={column.tagColor as Color}
												className="ml-auto"
											>
												{column.title}
											</BadgeLabel>
										) : null}
									</CommandItem>
								);
							})}
					</CommandGroup>
				</ScrollArea>
			</CommandList>

			<div className="border-stroke-tertiary flex flex-row items-center justify-between gap-2 border-t p-2">
				<Badge variant="secondary" className="rounded-md font-[350]">
					Open with ⌘K
				</Badge>
				<Badge variant="secondary" className="rounded-md font-[350]">
					Enter to select
				</Badge>
			</div>
		</CommandDialog>
	);
};
